<template>
    <div class="top-cart-item clearfix" ref="cartProductEl">
        <div class="top-cart-item-image">
            <img :src="thumbnailURL" :alt="getLocalizedText(cartProduct.apartment.title)">
        </div>
        <div class="top-cart-item-desc">
            <div class="top-cart-item-desc-title">
                <router-link v-if="true" :to="getViewDetailsPath(cartProduct)">
                    {{getLocalizedText(cartProduct.apartment.title)}}
                </router-link>
                <a v-else href="javascript:void(0)">
                    {{getLocalizedText(cartProduct.apartment.title)}}
                </a>
                <span class="top-cart-item-price d-block">
                    {{ formatPriceAmount(cartProduct.amount) }}
                    <span class="top-cart-item-capacity"><span>-</span><span>{{getLocalizedText(computedLabels.numberOfNights, {params:[numberOfNights]})}}</span></span>
                </span>
            </div>
            <div class="top-cart-item-quantity">x {{cartProduct.quantity}}</div>
        </div>
        <div class="top-cart-item-buttons">
            <button class="button button-dark button-rounded remove ladda-button" data-style="zoom-in" @click="onRemoveClick" :title="$cms.utils.getComputedLabel(computedLabels.removeButtonTitle)"><i class="icon-trash2 m-0"></i></button>
        </div>
    </div>
</template>

<style>

/* We set the size of the picture */
.top-cart-item-image img {
    width:auto!important;
    margin-left:auto;
    margin-right:auto;
}

.top-cart-item .top-cart-item-desc-title {
    width:max-content;
}

/* We ensure some space between title and quantity */
.top-cart-item .top-cart-item-desc-title a {
    margin-right:5px;
}

/* We ensure that price and capacity are on the same line */
.top-cart-item .top-cart-item-price {
    white-space: nowrap;
}

/* We ensure some space between price and capacity */
.top-cart-item .top-cart-item-capacity > span {
    margin-left:5px;
}

/* we ensure that quantity text is on one line */
.top-cart-item .top-cart-item-quantity {
    white-space: nowrap;
}

/* We set some space and separator with remove button */
.top-cart-item .top-cart-item-buttons {
    margin-left: 5px;
    border-left: 1px solid #eee;
    padding-left: 5px;
}

/* We set size of the remove button */
.top-cart-item .top-cart-item-buttons button.remove {
    padding: 0 8px;
    margin:0px;
    height: 36px;
    line-height: 38px;
}

/* We set size of the remove button */
.top-cart-item .top-cart-item-buttons button.remove > i {
    font-size: 18px;
}

</style>

<script lang="ts">
import * as Ladda from 'ladda';

import { CmsContent, CmsEnum, CmsLabel, CmsPicture, CmsText, CmsFile, CmsNumber, CmsBoolean } from '@fwk-client/modules/cms/types';
import { defineComponent, PropType, Ref, ref, computed, onMounted } from '@fwk-node-modules/vue'
import { getApp, useCmsModule, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { useProduct } from '@root/src/client/composables/useProduct'
import { diff } from '@igotweb-node-api-utils/date';

/** @cmsOptions */
export interface TopCartRentalOptions {
    
}

/** @cmsLabels */
export interface TopCartRentalLabels {
    /** @cmsType CmsLabel */
    removeButtonTitle:CmsLabel
    /** @cmsType CmsLabel */
    numberOfNights:CmsLabel
}

export const defaultLabels:TopCartRentalLabels = {
    removeButtonTitle : {
        fr : "Enlever le produit du panier",
        de : "Produkt aus dem Warenkorb entfernen",
        en : "Remove the product from cart"
    },
    numberOfNights : {
        fr : "{0} nuits",
        en : "{0} nights"
    }
}

/** @cmsSlots */
export interface TopCartRentalSlots {
    
}

export default defineComponent({
    props: {
        options: Object as PropType<TopCartRentalOptions>,
        labels: {
          type: Object as PropType<TopCartRentalLabels>,
          default: () => { return {} }
        },
        components: Object as PropType<TopCartRentalSlots>,
        cartProduct: Object as PropType<any>
    },
    components : {
        
    },
    setup(props, context) {

        const app = getApp();
        const $router = useRouter();
        const $store = useStore();
        const cms = useCmsModule();

        const computedOptions:TopCartRentalOptions = {
            ...props.options,
        };

        const { getThumbnailsURLs, getViewDetailsPath } = useProduct({
            type:"hospitality"
        }, context);

        const computedLabels:TopCartRentalLabels = {
            ...defaultLabels,
            ...props.labels
        }

        const cartProductEl:Ref<HTMLElement|null> = ref(null);

        var laddaSubmit:Ladda.LaddaButton|null = null;

        const currentLanguageCode = computed(() => {
			return $store.state.languages.currentLanguageCode;
		})

        onMounted(() => {
            // @ts-ignore
            var button:HTMLButtonElement|null = cartProductEl.value.querySelector( 'button.ladda-button' );
            laddaSubmit = Ladda.create(button!);
        })

        const thumbnailURL = computed(() => {
            return getThumbnailsURLs(props.cartProduct)[0];
        })

        const numberOfNights = diff(props.cartProduct.startDate,props.cartProduct.endDate).days;

        const onRemoveClick = (evt:Event) => {
            evt.preventDefault();

            laddaSubmit!.start();
            app.$hospitality.cart.removeRental(props.cartProduct).then((response:any) => {
                laddaSubmit!.stop();
            });
        }

        return {
            cartProductEl,
            cartProduct:props.cartProduct,
            currentLanguageCode,
            computedLabels,
            computedOptions,
            thumbnailURL,
            onRemoveClick,
            getViewDetailsPath,
            numberOfNights
        }
    }
})

</script>